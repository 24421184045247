<template>
  <v-container
    class="fill-height"
    :class="isPresensi ? 'background-green' : 'background-blue'"
    fluid
  >
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6" lg="4" xl="4" xs="12">
        <v-card-actions class="justify-center">
          <v-avatar color="white" class="my-10 pa-2" height="100" width="100">
            <v-img
              :src="require('@/assets/images/logo-tutwuri.png')"
              contain
              height="70"
            />
          </v-avatar>
        </v-card-actions>
        <p class="text-center ma-0 white--text">
          APLIKASI {{ isPresensi ? "PRESENSI" : "SIMPEG" }}
        </p>
        <p class="text-center font-weight-bold white--text">
          PAUD DIKDAS DIKMEN
        </p>
        <v-card class="rounded-lg mt-15">
          <v-card-text class="pt-5 px-0">
            <v-form
              id="check-login-form"
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="onSubmit(password, passwordConfirm, otp)"
            >
              <v-row justify="center" class="px-4">
                <v-col cols="12" class="py-0">
                  <v-text-field
                    solo
                    flat
                    v-model="username"
                    placeholder="OTP"
                    disabled
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider class="mt-2 mb-1"></v-divider>
              <v-row justify="center" class="px-4">
                <v-col cols="12" class="py-0">
                  <v-text-field
                    solo
                    flat
                    v-model="password"
                    :rules="passwordRules"
                    placeholder="Password"
                    type="password"
                    required
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider class="mt-2 mb-1"></v-divider>
              <v-row justify="center" class="px-4">
                <v-col cols="12" class="py-0">
                  <v-text-field
                    solo
                    flat
                    v-model="passwordConfirm"
                    :rules="passwordConfirmRules"
                    placeholder="Konfirmasi Password"
                    type="password"
                    required
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider class="mt-2 mb-1"></v-divider>
              <v-row justify="center" class="px-4">
                <v-col cols="12" class="py-0">
                  <v-text-field
                    solo
                    flat
                    v-model="otp"
                    :rules="otpRules"
                    placeholder="OTP"
                    required
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-text class="button">
            <v-row cols="8" align="center" justify="center">
              <v-btn
                :disabled="!valid"
                :formLoading="formLoading"
                block
                text
                type="submit"
                form="check-login-form"
              >
                <p class="ma-auto text-capitalize">
                  Ubah
                </p>
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from "@/services/resources/auth.service";
import { mapSummaryRootField } from "@/store/helpers";

export default {
  data() {
    return {
      version: process.env.VERSION ?? null,
      username: this.$route.query ? this.$route.query.username : null,
      valid: true,
      formLoading: false,
      e1: true,
      otp: null,
      otpRules: [v => !!v || "OTP is required"],
      password: null,
      passwordRules: [v => !!v || "Password is required"],
      passwordConfirm: null,
      passwordConfirmRules: [v => !!v || "Password Confirmation is required"]
    };
  },
  computed: {
    ...mapSummaryRootField({
      isPresensi: "setting.isPresensi"
    })
  },
  methods: {
    onSubmit(password, passwordConfirm, otp) {
      if (this.$refs.form.validate()) {
        this.resetPassword({
          username: this.username,
          password: password,
          password_confirmation: passwordConfirm,
          reset_token: otp
        });
      }
    },

    // Service
    async resetPassword(payload) {
      try {
        this.formLoading = true;
        await AuthService.resetPassword(payload)
          .then(response => {
            const { message, status } = response.data;
            if (status) {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
              this.getToPage("login");
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            this.$store.commit("snackbar/setSnack", {
              show: true,
              message: error.response.data.message,
              color: "error"
            });
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>
<style>
.background {
  background-color: #297bbf;
  height: "100%";
}

.button {
  background-color: #fbb005;
}
</style>
